export const FORM_FIELDS = {
  signIn: {
    username: {
      placeholder: "Enter your username or email",
      labelHidden: false,
      isRequired: true,
    },
    password: {
      labelHidden: false,
      isRequired: true,
    },
  },
  signUp: {
    username: {
      placeholder: "Enter your username",
      label: "Username ", //leave space after the label or it will add email by default
      order: 1,
      required: true,
    },
    phone_number: {
      order: 2,
      required: true,
    },
    given_name: {
      type: "text",
      order: 3,
      required: true,
    },
    family_name: {
      type: "text",
      order: 4,
      required: true,
    },
    email: {
      order: 5,
      type: "email",
      required: true,
    },
    password: {
      order: 6,
    },
    confirm_password: {
      order: 7,
    },
  },
};
